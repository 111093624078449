










































































import Vue from 'vue'
import { mapGetters } from 'vuex'
import CreditLine from '../../../../../../store/models/CreditLine'
export default Vue.extend({
  data: function() {
    return {
      search: '',
      searching: true,
      selectedLine: {
        name: '',
        id: ''
      },
      isLoading: false
    }
  },
  computed: {
    ...mapGetters('product', ['getCurrentProduct']),
    lines(): CreditLine[] | [] {
      const lines = CreditLine.getCompletedForCurrentOrganization()

      return lines.filter(line => {
        return (
          this.search.length &&
          line.name.toLowerCase().includes(this.search.toLowerCase())
        )
      })
    }
  },

  methods: {
    listDispatchings() {
      this.$router.push({
        name: 'finances.refunds.consignations.listDispatchings',
        params: {
          lineId: this.selectedLine.id
        }
      })
    },
    selectLine(line: CreditLine) {
      this.searching = false
      this.search = line.name
      this.selectedLine = line
    },
    goNext() {
      this.$router.push({
        name: 'finances.refunds.consignations.searchCreditLine'
      })
    }
  },
  created() {
    CreditLine.api().fetchForCurrentOrganization()
  }
})
